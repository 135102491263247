import React, { Component } from 'react';
import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';

const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
};

class GymOwners extends Component {
    render() {
        const { ownersGallery, ownersTitle, ownersContent } = this.props;
        return (
            <>
                <section
                    className="page-section smallestwdt"
                    id="aboutsliderdiv"
                >
                    <div className="container">
                        <h2
                            className="bluetxt"
                            dangerouslySetInnerHTML={{
                                __html: ownersTitle,
                            }}
                        ></h2>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: ownersContent,
                            }}
                        ></p>
                        <div className="carousel slide dots_about slider_owners">
                            <Slider {...settings} className="carousel-inner">
                                {ownersGallery.map(galleryImage => (
                                    <div
                                        className="item carousel-item"
                                        key={galleryImage.id}
                                    >
                                        <img
                                            className="margin-0 width_100"
                                            src={
                                                galleryImage.localFile
                                                    .childImageSharp.fluid.src
                                            }
                                            loading="lazy"
                                            alt="placeholder"
                                        />
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default GymOwners;
