import React, { Component } from 'react';
import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';

const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    lazyLoad: 'ondemand',
};

class GymIncluded extends Component {
    render() {
        const {
            gymIncludesTitle,
            gymIncludesContent,
            gymIncludesGallery,
        } = this.props;
        return (
            <>
                <section
                    className="page-section bg-primary text-white"
                    id="aboutincludes"
                >
                    <div className="container middlewdt">
                        <h2
                            className="yellowtxt"
                            dangerouslySetInnerHTML={{
                                __html: gymIncludesTitle,
                            }}
                        />
                        <div
                            className="halfdiv halflft"
                            dangerouslySetInnerHTML={{
                                __html: gymIncludesContent,
                            }}
                        />

                        <div className="halfdiv halfrgt aboutus_slider">
                            <div
                                id="myCarousel2"
                                className="carousel slide"
                                data-ride="carousel"
                            >
                                <Slider
                                    {...settings}
                                    className="carousel-inner"
                                >
                                    {gymIncludesGallery.map(galleryImage => (
                                        <div
                                            className="item carousel-item active"
                                            key={galleryImage.id}
                                        >
                                            <img
                                                className="margin-0"
                                                src={
                                                    galleryImage.localFile
                                                        .childImageSharp.fluid
                                                        .src
                                                }
                                                loading="lazy"
                                                alt="title"
                                            />
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default GymIncluded;
